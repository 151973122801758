import { Jodit } from 'jodit-react';
import { JoditEditorConnector } from './JoditEditorConnector';

export type Props = {
  onFocus?: () => void;
  onValueChanged?: () => void; // the value is in the connector
  isOneLine?: boolean;
  isWithoutStyles?: boolean;
  customHeight?: number;
  withoutToolbar?: boolean; // change to disable styles
  disabled?: boolean;
  customActions?: string[];
  placeholder?: string;
  editorConnector?: JoditEditorConnector;
};

export const arePropsEqual = (prevProps: Props, nextProps: Props, propKeys: (keyof Props)[]): boolean => {
  return propKeys.every((key) => prevProps[key] === nextProps[key]);
};

export const getConfig = (props: Props) => ({
  readonly: props.disabled,
  disabled: props.disabled,
  placeholder: props.placeholder,
  statusbar: !props.isOneLine,
  useSearch: true,
  addNewLine: false,
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showStatusbar: false,
  enter: 'br' as any,
  toolbar: !props.withoutToolbar,
  askBeforePasteFromWord: false,
  defaultActionOnPasteFromWord: props.isWithoutStyles ? 'insert_only_text' : 'insert_clear_html',
  hidePoweredByJodit: true,
  minHeight: props.isOneLine ? 30 : props.customHeight || 340,
  events: {
    afterInit: (instance: Jodit) => {
      props.editorConnector?.setEditor(instance);
      // (window as any).editor = editor; // just for debug purposess
    },
    focus: () => {
      props.onFocus?.();
    },
    beforeEnter: (event: any) => {
      if (props.isOneLine) {
        event.preventDefault();
        return false;
      }
    },
    beforeCommand: (command: string) => {
      const allowedCommands = ['backspacebutton', 'delete', 'deletebutton'];
      if (props.isWithoutStyles && !allowedCommands.includes(command)) {
        return false;
      }
      return true;
    },
  },
  // disablePlugins: 'hotkeys',
  // disablePlugins: props.isOneLineWithoutStyles
  //   ? ['add-new-line', 'pasteFromWord', 'paste']
  //   : ['add-new-line', 'singleLineWithoutStyles'],
  buttons: props.customActions
    ? props.customActions
    : [
        'paragraph',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'align',
        'outdent',
        'indent',
        '|',
        'ul',
        'ol',
        '|',
        'table',
        'image',
        'link',
        'hr',
        '|',
        'undo',
        'redo',
      ],
});
