import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';

import { useMsalInstance } from '../hooks/useMsalInstance';
import { msalConfig } from '../auth/authConfig';
import { setMsalInstance } from '../auth/authUtils';
import ReactDOM from 'react-dom';

interface AuthContextType {
  lastAttemptedRoute: string;
  setLastAttemptedRoute: (route: string) => void;
}

const AuthContext = createContext<AuthContextType>({
  lastAttemptedRoute: '/',
  setLastAttemptedRoute: () => {},
});

export function AuthProvider(props: PropsWithChildren) {
  const msalInstance = useMsalInstance(msalConfig);
  setMsalInstance(msalInstance);

  const [lastAttemptedRoute, setLastAttemptedRoute] = useState<string>('/');
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await msalInstance.initialize();
        setInitialized(true);
      } catch (error) {
        console.error('Error initializing MSAL:', error);
      }
    })();
  }, [msalInstance]);

  if (!initialized) {
    return <></>;
  }

  return (
    <AuthContext.Provider value={{ lastAttemptedRoute, setLastAttemptedRoute }}>
      <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
