import React from 'react';
import MobileIcon from '../../../../Icons/MobileIcon';

type Props = {
  body?: string;
  bodyLength?: number;
};

export default function SmsPreview(props: Props) {
  return (
    <>
      <MobileIcon />
      <div className="sms-preview">
        <div className="sms-bubble" dangerouslySetInnerHTML={{ __html: props.body || '' }}></div>
        {props.bodyLength !== undefined && <div className="sms-footer-info">Chars: {props.bodyLength}</div>}
      </div>
    </>
  );
}
