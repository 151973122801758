import { Formik, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

import {
  ParameterEnum,
  Parameter,
  SysDataTypeId,
  SysParameterTypeId,
  useLazyParameterQuery,
  useParametersQuery,
  TrimType,
} from '../../../api/generated';
import { FormikField } from '../../../components/FormikField';
import FaTrashWhiteIcon from '../../../components/Icons/FaTrashWhiteIcon';
import { useValidationSchema } from './useValidationSchema';
import SampleData from '../../../components/SampleData';
import { getTrimTypeOptions } from '../../../common/enumDisplayName';

export type Props = {
  parameter: Parameter;
  isNew: boolean;
  isLoading: boolean;
  onConfirm: (parameter: Parameter) => void;
  onClose: () => void;
};

function ParameterEditForm({ parameter, isNew, isLoading, onConfirm, onClose }: Props): JSX.Element | null {
  const [sampleValue, setSampleValue] = useState<string>(parameter?.sampleValue || '');
  const [sysParameterTypeId, setSysParameterTypeId] = useState<SysParameterTypeId>(parameter?.sysParameterTypeId || '');
  const [sysCategory, setSysCategory] = useState<string>(parameter?.sysCategory || '');
  const [sysDataType, setSysDataType] = useState<SysDataTypeId>(parameter?.sysDataTypeId || '');

  const [enumItems, setEnumItems] = useState(parameter?.enumItems ?? []);
  const sysParameterTypes = Object.keys(SysParameterTypeId);

  const [sysCategoryParameter] = useLazyParameterQuery({});
  const [sysCategories, setSysCategories] = useState<ParameterEnum[]>();

  const { data: parameters } = useParametersQuery();
  const validationSchema = useValidationSchema(
    parameters?.allParameters?.filter((item) => item?.name !== parameter?.name).map((item) => item?.name || '') || [],
  );

  useEffect(() => {
    const sysCategoryId = parameters?.allParameters?.find((item) => item?.systemName === 'SysCategory')?.parameterId;
    if (sysCategoryId) {
      sysCategoryParameter({ id: sysCategoryId.toString() })
        .unwrap()
        .then((parameter) => {
          setSysCategories(parameter.parameterById?.enumItems as any);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    if (isNew && (sysDataType === SysDataTypeId.Bool || sysDataType === SysDataTypeId.Enum)) {
      setSysParameterTypeId(SysParameterTypeId.Decision);
    } else {
      setSysParameterTypeId(SysParameterTypeId.DataHolder);
    }
  }, [isNew, sysDataType]);

  return (
    <>
      <Formik
        initialValues={parameter}
        validationSchema={validationSchema}
        onSubmit={(formik) => {
          onConfirm({
            ...parameter,
            name: formik.name,
            description: formik.description,
            sampleValue,
            sysParameterTypeId,
            sysCategory,
            sysDataType: { name: sysDataType, sysDataTypeId: sysDataType },
            queryStringName: formik.queryStringName,
            anonymize: formik.anonymize,
            mandatory: formik.mandatory,
            trimType: formik.trimType,
            trimMaxLength: formik.trimMaxLength,
            enumItems,
          });
        }}
      >
        {({ submitForm }) => (
          <>
            <div className="side-panel-content side-panel-content-section parameter-edit-form">
              <FormikField label="Technical name" type="text" name="queryStringName" />
              <FormikField label="Name" type="text" name="name" />
              <FormikField label="Description" type="textarea" name="description" />

              <div className="field">
                <label htmlFor="sysDataType">Data type</label>
                <Dropdown
                  id="sysDataType"
                  name="sysDataType"
                  value={sysDataType}
                  placeholder="Select value"
                  className={classNames('w-full', { 'p-invalid': false })}
                  onChange={(event) => {
                    setSampleValue('');
                    setSysDataType(event.target.value as SysDataTypeId);
                  }}
                  options={Object.keys(SysDataTypeId).map((type) => {
                    return {
                      label: type,
                      value: SysDataTypeId[type as keyof typeof SysDataTypeId],
                    };
                  })}
                />
              </div>

              {sysDataType === SysDataTypeId.Enum && <ParameterEnumItems items={enumItems} setItems={setEnumItems} />}

              <div className="field">
                <label htmlFor="sampleValue">Sample data</label>
                <SampleData
                  type={sysDataType}
                  value={sampleValue}
                  setValue={setSampleValue}
                  enumItems={enumItems}
                  isSimple={false}
                />
              </div>

              <div className="field">
                <label htmlFor="sysCategory">Provider</label>
                <Dropdown
                  id="sysCategory"
                  name="sysCategory"
                  value={sysCategory}
                  placeholder="Select value"
                  className={classNames('w-full', { 'p-invalid': false })}
                  onChange={(event) => setSysCategory(event.target.value)}
                  options={sysCategories?.map((category) => {
                    return { label: category.value, value: category.key };
                  })}
                />
              </div>

              <div className="field">
                <label htmlFor="sysParameterTypeId">Parameter usage</label>
                <SetParameterUsage
                  type={sysDataType}
                  sysParameterTypes={sysParameterTypes}
                  setSysParameterTypeId={setSysParameterTypeId}
                  isNew={isNew}
                  sysParameterType={sysParameterTypeId}
                  oldSysParameterType={parameter.sysParameterTypeId}
                />
              </div>

              <FormikField label="Anonymize" type="checkbox" name="anonymize" />
              <FormikField label="Mandatory" type="checkbox" name="mandatory" />

              <FormikField label="Trim type" type="select" name="trimType" options={getTrimTypeOptions()} />
              <FormikTrimMaxLengthField />
            </div>
            <div className="side-panel-controls">
              <Button
                label={isNew ? 'Create' : 'Save'}
                disabled={isLoading}
                className="p-button-sm"
                onClick={submitForm}
                type="submit"
              />
              <Button label="Cancel" type="button" className="p-button-sm p-button-secondary" onClick={onClose} />
            </div>
          </>
        )}
      </Formik>
    </>
  );
}

function FormikTrimMaxLengthField() {
  const { values } = useFormikContext<Parameter>();

  return (
    <>
      {values.trimType !== TrimType.None && <FormikField label="Trim max length" type="number" name="trimMaxLength" />}
    </>
  );
}

type ParameterEnumItemsProps = {
  items: ParameterEnum[];
  setItems: (items: ParameterEnum[]) => void;
};

function ParameterEnumItems({ items, setItems }: ParameterEnumItemsProps): JSX.Element {
  return (
    <div className="field">
      <label htmlFor="enumItems">Enums</label>
      {items.map((item, index: number) => (
        <div key={index} className="enum-items">
          <InputText
            id="key"
            name="key"
            placeholder="key"
            value={item.key}
            // className={classNames('w-full', { 'p-invalid': false })}
            onChange={(event) =>
              setItems(
                items.map((newItem, newIndex) =>
                  newIndex === index
                    ? { key: event.target.value, value: newItem.value, enabled: newItem.enabled }
                    : newItem,
                ),
              )
            }
          />
          <InputText
            id="value"
            name="value"
            placeholder="value"
            value={item.value}
            // className={classNames('w-full', { 'p-invalid': false })}
            onChange={(event) =>
              setItems(
                items.map((newItem, newIndex) =>
                  newIndex === index
                    ? { key: newItem.key, value: event.target.value, enabled: newItem.enabled }
                    : newItem,
                ),
              )
            }
          />
          <Button
            className="p-button-sm"
            icon={<FaTrashWhiteIcon />}
            onClick={() => setItems(items.filter((newItem) => newItem.key !== item.key))}
          />
        </div>
      ))}
      <div>
        <Button
          label="Add"
          className="p-button-sm p-button-secondary"
          onClick={() => setItems([...items, { key: '', value: '', enabled: false }])}
        />
      </div>
    </div>
  );
}

function SetParameterUsage({
  type,
  sysParameterTypes,
  setSysParameterTypeId,
  isNew,
  sysParameterType,
  oldSysParameterType,
}: {
  type: SysDataTypeId;
  sysParameterTypes: string[];
  setSysParameterTypeId: (value: SysParameterTypeId) => void;
  isNew: boolean;
  sysParameterType: SysParameterTypeId;
  oldSysParameterType: SysParameterTypeId;
}) {
  let newValue = isNew ? sysParameterType : oldSysParameterType;

  return (
    <Dropdown
      id="sysParameterTypeId"
      name="sysParameterTypeId"
      value={newValue}
      placeholder="Select value"
      disabled={true}
      className={classNames('w-full', { 'p-invalid': false })}
      onChange={(event) => setSysParameterTypeId(event.target.value)}
      options={sysParameterTypes.map((type) => {
        return { label: type, value: SysParameterTypeId[type as keyof typeof SysParameterTypeId] };
      })}
    />
  );
}

export default ParameterEditForm;
