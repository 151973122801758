import { createApi } from '@reduxjs/toolkit/query/react';
import { GraphQLClient } from 'graphql-request';

import { graphqlRequestBaseQuery } from './graphqlRequestBaseQuery';
import { acquireTokenSilently } from '../auth/authUtils';

const url = process.env.REACT_APP_GRAPHQL_API as string;
const uri = (process.env.REACT_APP_MODE as string) === 'Development' ? '/proxy' : url;

export const client = new GraphQLClient(uri);

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    options: {
      client,
    },
    prepareHeaders: async (headers) => {
      const token = await acquireTokenSilently();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
