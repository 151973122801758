import { IMatrixPreviewData } from './useMatrixPreview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../../../Loader';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { NestedParameterSection } from '../../../Editor/components/NestedParametersSection';
import { useState } from 'react';
import { KeyValuePairOfInt32AndString } from '../../../../api/generated';
import { useAdvanced } from '../../useAdvanced';
import { Dialog } from 'primereact/dialog';

type Props = {
  templateId: number;
  matrixPreviewData: IMatrixPreviewData;
  onParameterCombinationClick: (parameters: KeyValuePairOfInt32AndString[]) => void;
};

export function MatrixPreview(props: Props) {
  const [advanced] = useAdvanced();
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewContent, setPreviewContent] = useState('');

  const openPreview = (content: string) => {
    setPreviewContent(content);
    setpreviewVisible(true);
  };

  return (
    <div className="editor matrix-preview">
      {advanced && (
        <NestedParameterSection
          compactMode
          templateId={props.templateId}
          onValueChange={props.matrixPreviewData.setNestedParameterCustomValue}
        />
      )}
      {props.matrixPreviewData.isLoading ? (
        <div className="flex mt-4 align-items-center justify-content-center">
          <Loader size="3x" />
        </div>
      ) : (
        <>
          <DataTable
            className="w-full"
            value={props.matrixPreviewData.rows}
            loading={props.matrixPreviewData.isLoading}
            filterDisplay="row"
            removableSort
            onSort={(e) => {
              props.matrixPreviewData.setFilterOptions({
                field: e.sortField ? parseInt(e.sortField) : null,
                order: e.sortOrder,
              });
            }}
            sortField={props.matrixPreviewData.filterOptions.field?.toString()}
            sortOrder={props.matrixPreviewData.filterOptions?.order}
            onRowDoubleClick={(e) => {
              props.onParameterCombinationClick(props.matrixPreviewData.rows[e.index].parameterValues);
            }}
          >
            {props.matrixPreviewData.parameterColumns.map((col) => (
              <Column
                key={col.header}
                field={col.paramId?.toString()}
                header={col.header}
                sortField={col.paramId?.toString()}
                body={col.body}
                sortable
                filter={!!col.values}
                showFilterMenu={false}
                filterElement={
                  col.values && col.paramId ? (
                    <ParameterFilter
                      value={props.matrixPreviewData.filterValues.get(col.paramId)}
                      options={col.values}
                      setFilter={(value) => {
                        props.matrixPreviewData.handleFilterChange(col.paramId!, value);
                      }}
                    />
                  ) : undefined
                }
                filterMatchMode={FilterMatchMode.EQUALS}
              />
            ))}
            <Column header="Chars" field="bodyLength" style={{ width: '60px' }} />
            <Column header="Content" body={(data) => <ContentPreviewCell data={data} openPreview={openPreview} />} />
          </DataTable>
          <Dialog
            visible={previewVisible}
            style={{ width: '50vw' }}
            onHide={() => {
              if (!previewVisible) return;
              setpreviewVisible(false);
            }}
          >
            <div className="p-2" dangerouslySetInnerHTML={{ __html: previewContent }} />
          </Dialog>
        </>
      )}
    </div>
  );
}

interface IContentPreviewCellProps {
  data: any;
  openPreview: (content: any) => void;
}

function ContentPreviewCell({ data, openPreview }: IContentPreviewCellProps) {
  const hasBody = data.body !== null && data.body !== '';

  return (
    <div className="flex justify-between items-start">
      <ContentPreview contentId={data.contentId} subject={data.subject} body={data.body} />
      {hasBody && (
        <div className="pl-2">
          <i className="pi pi-eye cursor-pointer preview-icon-button" onClick={() => openPreview(data.body)} />
        </div>
      )}
    </div>
  );
}

interface IContentPreviewProps {
  contentId: number | null;
  body: string | null;
  subject: string | null;
}

function ContentPreview({ contentId, body, subject }: IContentPreviewProps) {
  const hasSubject = subject !== null && subject !== '';
  const hasBody = body !== null && body !== '';

  return (
    <div className="flex-1 matrix-preview-cell reset-table">
      {contentId && !hasSubject && !hasBody && (
        <div className="content-label">
          <Loader />
        </div>
      )}
      {!contentId && !hasSubject && !hasBody && (
        <div className="content-label">This content is empty and has no value</div>
      )}
      {hasSubject && (
        <>
          <div className="content-label">Subject</div>
          <div dangerouslySetInnerHTML={{ __html: subject }} />
        </>
      )}
      {hasSubject && hasBody && <div className="content-label">Body</div>}
      {hasBody && <div dangerouslySetInnerHTML={{ __html: body }} />}
    </div>
  );
}

interface IParameterFilterProps {
  value: string | undefined;
  options: Map<string, string | JSX.Element>;
  setFilter: (value: string | undefined) => void;
}

function ParameterFilter(props: IParameterFilterProps) {
  return (
    <Dropdown
      value={props.value}
      options={Array.from(props.options, ([key, value]) => ({ label: value, value: key }))}
      onChange={(e) => props.setFilter(e.value)}
      placeholder="Select one"
      showClear
    />
  );
}
