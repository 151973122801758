import { TrimType } from '../api/generated';

export function getTrimTypeDisplayName(trimType: TrimType): string {
  switch (trimType) {
    case TrimType.None:
      return 'None';
    case TrimType.ByChars:
      return 'By chars';
    case TrimType.ByWords:
      return 'By words';
  }
}

export function getTrimTypeOptions(): { id: string; name: string }[] {
  return Object.values(TrimType).map((value) => ({
    id: value,
    name: getTrimTypeDisplayName(value as TrimType),
  }));
}
