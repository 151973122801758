import { Formik } from 'formik';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { addCustomYupMethods } from '../../../../common/validationUtils';
import { FormikField } from '../../../FormikField';
import { Dialog } from 'primereact/dialog';
import { useCallback } from 'react';
import { MessageType } from '../../editorTypes';
import {
  ParameterInput,
  RecipientInput,
  useSendTemplatePreviewByContentParametersMutation,
} from '../../../../api/generated';
import useToast from '../../../../hooks/useToast';

const PHONE_STORAGE_KEY = 'phone-number-preview';
const EMAIL_STORAGE_KEY = 'email-preview';
const NOTIFICATION_STORAGE_KEY = 'notification-preview';
addCustomYupMethods();

type Props = {
  messageType: MessageType;
  subject: string | undefined;
  body: string | undefined;
  params: ParameterInput[];
  onAccept?: () => void;
  onHide: () => void;
  visible: boolean;
};

interface SmsParameter {
  phoneNumber: string;
}

interface EmailParameter {
  email: string;
}

interface NotificationParameter {
  token: string;
}

export default function SendPreviewDialog(props: Props) {
  return (
    <Dialog header="Send Preview" onHide={props.onHide} visible={props.visible} style={{ width: 300 }}>
      {props.messageType === MessageType.Sms && <SendSmsPreviewForm {...props} />}
      {props.messageType === MessageType.Email && <SendEmailPreviewForm {...props} />}
      {props.messageType === MessageType.Notification && <SendNotificationPreviewForm {...props} />}
    </Dialog>
  );
}

function SendSmsPreviewForm(props: Props) {
  const sendPreview = useSendPreview();

  const parameter: SmsParameter = {
    phoneNumber: localStorage.getItem(PHONE_STORAGE_KEY) || '',
  };

  const sendSmsPreview = useCallback(
    async (param: SmsParameter) => {
      localStorage.setItem(PHONE_STORAGE_KEY, param.phoneNumber);
      sendPreview(props.subject, props.body, props.params, { phoneNumber: param.phoneNumber });
      props.onHide();
    },
    [props, sendPreview],
  );

  return (
    <Formik initialValues={parameter} validationSchema={getSmsValidationSchema()} onSubmit={sendSmsPreview}>
      {({ submitForm }) => (
        <>
          <div>
            <FormikField label="Phone number" type="text" name="phoneNumber" />
          </div>
          <div className="p-dialog-footer mt-5">
            <Button label="Cancel" className="p-button-cancel" onClick={props.onHide} />
            <Button label={'Send'} className="p-button p-confirm-dialog-accept" onClick={submitForm} autoFocus />
          </div>
        </>
      )}
    </Formik>
  );
}

function SendEmailPreviewForm(props: Props) {
  const sendPreview = useSendPreview();

  const parameter: EmailParameter = {
    email: localStorage.getItem(EMAIL_STORAGE_KEY) || '',
  };

  const sendEmailPreview = useCallback(
    async (formik: EmailParameter) => {
      localStorage.setItem(EMAIL_STORAGE_KEY, formik.email);
      sendPreview(props.subject, props.body, props.params, { email: formik.email });
      props.onHide();
    },
    [props, sendPreview],
  );

  return (
    <Formik initialValues={parameter} validationSchema={getEmailValidationSchema()} onSubmit={sendEmailPreview}>
      {({ submitForm }) => (
        <>
          <div>
            <FormikField label="Email" type="text" name="email" />
          </div>
          <div className="p-dialog-footer mt-5">
            <Button label="Cancel" className="p-button-cancel" onClick={props.onHide} />
            <Button label={'Send'} className="p-button p-confirm-dialog-accept" onClick={submitForm} autoFocus />
          </div>
        </>
      )}
    </Formik>
  );
}

function SendNotificationPreviewForm(props: Props) {
  const sendPreview = useSendPreview();

  const parameter: NotificationParameter = {
    token: localStorage.getItem(NOTIFICATION_STORAGE_KEY) || '',
  };

  const sendNotificationPreview = useCallback(
    async (param: NotificationParameter) => {
      localStorage.setItem(NOTIFICATION_STORAGE_KEY, param.token);
      sendPreview(props.subject, props.body, props.params, { notificationToken: param.token });
      props.onHide();
    },
    [props, sendPreview],
  );

  return (
    <Formik
      initialValues={parameter}
      validationSchema={getNotificationValidationSchema()}
      onSubmit={sendNotificationPreview}
    >
      {({ submitForm }) => (
        <>
          <div>
            <FormikField label="Notification token" type="text" name="token" />
          </div>
          <div className="p-dialog-footer mt-5">
            <Button label="Cancel" className="p-button-cancel" onClick={props.onHide} />
            <Button label={'Send'} className="p-button p-confirm-dialog-accept" onClick={submitForm} autoFocus />
          </div>
        </>
      )}
    </Formik>
  );
}

function useSendPreview() {
  const toast = useToast();
  const [sendPreview] = useSendTemplatePreviewByContentParametersMutation();

  return useCallback(
    async (
      subject: string | undefined,
      body: string | undefined,
      parameters: ParameterInput[],
      recipient: RecipientInput,
    ) => {
      await sendPreview({
        input: { subject, body, parameters, recipient },
      })
        .unwrap()
        .then((result) => {
          result ? toast.success('Preview has been sent.') : toast.error('Failed to send preview.');
        })
        .catch(toast.error);
    },
    [sendPreview, toast],
  );
}

function getSmsValidationSchema() {
  return Yup.object().shape({
    phoneNumber: Yup.string()
      //@ts-ignore
      .phone(true)
      .required('Phone number is required'),
  });
}

function getEmailValidationSchema() {
  return Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  });
}

function getNotificationValidationSchema() {
  return Yup.object().shape({
    token: Yup.string().max(256).required('Notification token is required'),
  });
}
