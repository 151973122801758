// src/auth/authUtils.ts
import { jwtDecode } from 'jwt-decode';
import { ApiRole } from '../api/ApiRole.types';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';

let msalInstance: PublicClientApplication | null = null;

export const checkAuth = (roles: ApiRole[], requirements?: ApiRole[], token?: string): boolean => {
  // If there's no token, the user is not authenticated
  if (!token) {
    return false;
  }

  if (isTokenExpired(token)) {
    return false;
  }

  if (roles.some((role) => role === ApiRole.Admin)) {
    return true;
  }

  // If specific role requirements are provided, check if the user meets them
  if (requirements && requirements.length > 0) {
    // This example assumes that you decode the token and extract roles from it
    // You might use a library like `jwt-decode` to decode JWT tokens

    // Check if user has at least one of the required roles
    return requirements.some((role) => roles.includes(role));
  }

  return false;
};

export const isTokenExpired = (token: string): boolean => {
  const decoded: { exp: number } = jwtDecode(token);
  const currentTime = Date.now() / 1000; // current time in seconds
  return decoded.exp < currentTime;
};

export function getMsalInstance(): PublicClientApplication {
  if (!msalInstance) {
    throw new Error('msalInstance is not set. Call setMsalInstance(...) first.');
  }

  return msalInstance;
}

export function setMsalInstance(instance: PublicClientApplication) {
  msalInstance = instance;
}

export async function acquireTokenSilently(): Promise<string | undefined> {
  if (!msalInstance) {
    throw new Error('msalInstance is not set. Call setMsalInstance(...) first.');
  }

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    throw new Error('No user account in MSAL instance.');
  }

  const silentRequest = {
    scopes: ['User.Read'],
    account: accounts[0],
  };

  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response?.idToken;
  } catch (error) {
    console.error('Acquire token silent failed', error);
    if (error instanceof InteractionRequiredAuthError) {
      try {
        const response = await msalInstance.acquireTokenPopup(silentRequest);
        return response?.idToken;
      } catch (popupError) {
        console.error('Acquire token popup failed', popupError);
      }
    }
  }
}
