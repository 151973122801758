import { useCallback, useState } from 'react';

import useIsAuthenticated from './useIsAuthenticated';
import { useTokenContext } from '../providers/TokenProvider';

function useProfilePhoto() {
  const { accessToken } = useTokenContext();
  const [image, setImage] = useState<null | string>(null);
  const isAutenticated = useIsAuthenticated();

  const fetchData = useCallback(async () => {
    isAutenticated &&
      (await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: { Authorization: `${accessToken}` },
      })
        .then((response) => {
          if (response != null && response.ok) {
            response.blob().then((data) => {
              if (data !== null) {
                window.URL = window.URL || window.webkitURL;
                setImage(window.URL.createObjectURL(data));
              }
            });
          } else {
            console.warn('Profile image not found');
          }
        })
        .catch(console.error));
  }, [isAutenticated, accessToken]);

  return {
    image,
    fetchData,
  };
}

export default useProfilePhoto;
