import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useCallback, useEffect, useMemo } from 'react';

import { ApiRole } from '../api/ApiRole.types';
import { useRoles } from './useRoles';
import { useTokenContext } from '../providers/TokenProvider';
import { useProfilePhotoContext } from '../providers/ProfilePhotoProvider';

type TokenClaims = {
  roles: string[];
};

export function useMsalInstance(msalConfig: Configuration) {
  const instance = useMemo(() => new PublicClientApplication(msalConfig), [msalConfig]);

  const { setRoles, clearRoles } = useRoles();
  const profile = useProfilePhotoContext();
  const { clearToken } = useTokenContext();

  const handleLoginSuccess = useCallback(
    (event: EventMessage) => {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      instance.setActiveAccount(account);
      const claims = account?.idTokenClaims as TokenClaims;
      setRoles(claims.roles as ApiRole[]);
    },
    [instance, setRoles],
  );

  const handleLogoutSuccess = useCallback(() => {
    clearToken();
    clearRoles();
  }, [clearToken, clearRoles]);

  useEffect(() => {
    instance.enableAccountStorageEvents();

    const callbackId = instance.addEventCallback((event: EventMessage) => {
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md#table-of-events
      switch (event.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          handleLoginSuccess(event);
          profile.fetch();
          break;
        case EventType.LOGOUT_SUCCESS:
          handleLogoutSuccess();
          break;
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, handleLoginSuccess, handleLogoutSuccess, profile]);

  return instance;
}
